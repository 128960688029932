<template>
  <div>
    <div class="page-header py-2">
      <v-btn @click="goPrevious()" icon color="blue darken-2">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <b class="text-h6"> The Dream Maker </b>
      <div class="mx-2"></div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="x-card pa-2">
          <v-icon size="30" color="black">mdi-bookmark-outline</v-icon>
          <div class="mt-2 text-h6">Chapter 1</div>
          <div class="">Hamochi’s Daily Routine</div>
          <div class="x-card-action">
            <div>
              <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
              <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
              <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
            </div>
            <v-btn color="blue darken-3 px-6 mt-2" dark @click="goNext()" depressed>
              Read
            </v-btn>
          </div>
        </div>
        <div class="x-card pa-2">
          <v-icon size="30" color="black">mdi-bookmark-outline</v-icon>
          <div class="mt-2 text-h6">Chapter 2</div>
          <div class="">???</div>
          <div class="x-card-action">
            <div>
              <v-icon size="50" color="blue darken-2">mdi-lock</v-icon>
            </div>
            <div class="me-1"><v-icon size="20">mdi-star</v-icon> 1</div>
          </div>
        </div>
        <div class="x-card pa-2">
          <v-icon size="30" color="black">mdi-bookmark-outline</v-icon>
          <div class="mt-2 text-h6">Chapter 3</div>
          <div class="">???</div>
          <div class="x-card-action">
            <div>
              <v-icon size="40" color="black">mdi-lock-outline</v-icon>
            </div>
            <div class="me-1"><v-icon size="20">mdi-star</v-icon> 2</div>
          </div>
        </div>
        <div class="x-card pa-2">
          <v-icon size="30" color="black">mdi-bookmark-outline</v-icon>
          <div class="mt-2 text-h6">Chapter 4</div>
          <div class="">???</div>
          <div class="x-card-action">
            <div>
              <v-icon size="40" color="black">mdi-lock-outline</v-icon>
            </div>
            <div class="me-1"><v-icon size="20">mdi-star</v-icon> 3</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer py-3">
      <v-icon size="25" color="black" class="mx-2">mdi-star</v-icon>
      <v-progress-linear value="10" height="25" rounded color="blue darken-3">
        <strong>3/30</strong>
      </v-progress-linear>
      <v-btn class="px-8 mx-3" @click="goNext" height="25" disabled depressed>
        complete
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goNext() {
      this.$router.push({
        name: "ABCPage3",
        query: { autoplay: 1 },
      });
    },
    goPrevious() {
      this.$router.push({
        name: "ABCPage1",
      });
    },
  },
};
</script>

<style scoped>
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  top: 0;
  /* border-bottom: #000 solid 1px; */
  border: #000 solid 1px;
  display: flex;
  justify-content: space-between;
}
.page-footer {
  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  /* border-top: #000 solid 1px; */
  border: #000 solid 1px;
  display: flex;
  align-items: center;
}

.page-content {
  padding: 70px 20px 100px 20px;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.x-card {
  border: #000 1px solid;
  width: 100%;
  margin: 10px;
}

.x-card-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn-dis {
  opacity: 0.5;
}
</style>